import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AutotrimDirective, DialogFormActionsComponent} from '../common';
import {UserService} from '../common/service/user.service';
import {User} from '../model';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-user-profile-accept-terms-dialog',
  standalone: true,
  templateUrl: 'user-profile-accept-terms-dialog.component.html',
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, MatToolbarModule, MatInputModule, MatCheckboxModule,
    MatButtonModule, MatIconModule, DialogFormActionsComponent, AutotrimDirective
  ]
})
export class UserProfileAcceptTermsDialog {
  form = new FormBuilder().nonNullable.group({
    email: [{value: '', disabled: true}, Validators.required],
    name: ['', Validators.required],
    acceptedTerms: [false, Validators.requiredTrue]
  });

  constructor(private router: Router, private toastr: ToastrService,
              private service: UserService, private dialogRef: MatDialogRef<UserProfileAcceptTermsDialog>,
              @Inject(MAT_DIALOG_DATA) public user: User) {
    this.form.patchValue(user);
  }

  async save() {
    const {acceptedTerms, name} = this.form.value;
    await this.service.acceptTerms(acceptedTerms!!, name!!);
    this.toastr.success('success');
    const returnUrl = localStorage.getItem('returnUrl') || '/';
    localStorage.removeItem('returnUrl');
    await this.router.navigate([returnUrl]);
    this.dialogRef.close();
  }
}
