import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BaseListComponent} from '../../common';
import {MatChipsModule} from '@angular/material/chips';
import {ChainDocument, ProductChain} from '../../model';
import {ReportProductDocumentsComponent} from './report-product-documents.component';
import {DocumentService, PreviewDocument} from '../../common/service/document.service';

@Component({
  selector: 'app-documents-dialog',
  standalone: true,
  templateUrl: 'documents-dialog.component.html',
  styleUrls: ['documents-dialog.component.scss'],
  providers: [{provide: BaseListComponent, useExisting: DocumentsDialogComponent}],
  imports: [
    CommonModule, MatDialogModule, MatChipsModule, MatToolbarModule, MatButtonModule, MatIconModule,
    ReportProductDocumentsComponent
  ]
})
export class DocumentsDialogComponent implements OnInit {
  preview: { [key: string]: PreviewDocument } = {};
  documents: ChainDocument[] = [];

  constructor(
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: { product: ProductChain }) {
    this.documents = data.product.documents;
  }

  async ngOnInit() {
    this.preview = await this.documentService.previewList(this.documents.map(({id}) => id));
  }
}
