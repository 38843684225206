import {Component, HostListener, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ApiService} from '../../common/service/api.service';

@Component({
  selector: 'app-explore-land-dialog',
  standalone: true,
  templateUrl: 'explore-land-dialog.component.html',
  styleUrls: ['explore-land-dialog.component.scss'],
  imports: [CommonModule, MatDialogModule, MatToolbarModule, MatIconModule]
})
export class ExploreLandDialogComponent {
  url?: SafeResourceUrl;
  height: number = 0;

  constructor(api: ApiService, sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) data: { id: string }) {
    this.updateSize();
    api.exploreLandLink(data.id).then(({url}) => this.url = sanitizer.bypassSecurityTrustResourceUrl(url));
  }

  @HostListener('window:resize')
  updateSize() {
    this.height = window.innerHeight - 98;
  }

  loaded($event: Event) {
    console.log('iframe loaded', $event);
  }
}

