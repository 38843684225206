import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DialogFormActionsComponent} from './dialog-form-actions.component';

@Component({
  templateUrl: 'confirm-dialog.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatToolbarModule, DialogFormActionsComponent],
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, button: string }) {
  }
}
