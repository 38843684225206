<div class="detail" *ngIf="docDetail().length > 0">
  <div>
    <div class="col-name">Name</div>
    <div class="col-hscode">HS Code</div>
    <div class="col-quantity">Quantity</div>
  </div>
  <div *ngFor="let detail of docDetail()">
    <div class="col-name">{{ detail.name }}</div>
    <div class="col-hscode">{{ detail.hsCode }}</div>
    <div class="col-quantity">{{ detail.quantity }} {{ detail.unit }}</div>
  </div>
</div>


<div class="list">
  <div *ngFor="let doc of documents">
    <img *ngIf="preview[doc.id]; else noImage" [alt]="doc.title"
         [attr.src]="'data:image/png;base64,'+preview[doc.id].preview"/>
    <ng-template #noImage>
      <mat-icon>web_asset_off</mat-icon>
    </ng-template>

    <h5>
      {{ doc | mapSourceName }} / {{ doc.type.name }}
    </h5>

    <div *ngIf="doc.pending" class="new">** new document **</div>
  </div>
</div>
