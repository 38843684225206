import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, HasIdName, Team, User} from '../../model';
import {AuthUser} from '../auth/app-auth.service';

export interface TokenResponse {
  token: AppId;
}

export interface ErrorResponse {
  error: boolean;
}

export const isErrorResponse = (response: any): response is ErrorResponse => 'error' in response && response.error;

@Injectable({providedIn: 'root'})
export class UserService extends _Service<User> {
  constructor() {
    super('api/user');
  }

  acceptTerms = async (acceptedTerms: Boolean, name: string) =>
    this.http.put<any>('api/auth/profile-terms', {acceptedTerms, name});
  profile = async () =>
    this.http.get<User>('api/auth/profile');
  updateProfile = async (data: User) =>
    this.http.put<User>('api/auth/profile', data);
  team = async () =>
    this.http.get<Team>('api/user/team');
  fetchTeamToken = async () =>
    this.http.get<TokenResponse>('api/user/team/token');
  revokeTeamToken = async () =>
    this.http.delete<TokenResponse>('api/user/team/token');
  migrateTeamMember = async (token: string) =>
    this.http.post<Team | ErrorResponse>('api/user/team', {token});
  inviteTeamMember = async (name: string, email: string) =>
    this.http.post<Team | ErrorResponse>('api/user/team/invite', {name, email});
  removeTeamMember = async (user: User) =>
    this.http.delete<Team>(`api/user/team/${user.id}`);
  updateTeam = async (team: Team) =>
    this.http.put<Team>('api/user/team', team);
  impersonate = async (user: User) =>
    this.http.get<AuthUser>(`api/user/impersonate/${user.id}`);
  all = async () =>
    this.http.get<HasIdName[]>(`api/user/all`);
}
