import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import {firstValueFrom, mergeMap, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AsyncHttpClient {
  private readonly http = inject(HttpClient);

  get = async <T>(url: string) =>
    firstValueFrom(this.http.get<T>(url));
  getBlob = async (url: string) =>
    firstValueFrom(this.http.get(url, {observe: 'response', responseType: 'blob'}));
  getText = async (url: string) =>
    firstValueFrom(this.http.get(url, {responseType: 'text'}));
  post = async <T>(url: string, data: any) =>
    firstValueFrom(this.http.post<T>(url, data));
  postUpload = <T>(url: string, onProgress: (progress: number) => void, file: File,
                   type?: string, title?: string) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    if (type) formData.append('type', type);
    if (title) formData.append('title', title);
    return this.http.post<T>(url, formData, {reportProgress: true, observe: 'events'})
      .pipe(mergeMap((event) => {
        if (event.type === HttpEventType.UploadProgress && !!event.total) {
          onProgress(100 * event.loaded / event.total);
        }
        if (event instanceof HttpResponse) {
          return of(event.body as T);
        }
        return of();
      }));
  };
  postBlobXls = async (url: string, data: any) =>
    firstValueFrom(this.http.post(url, data, {responseType: 'blob', headers: {Accept: 'application/vnd.ms-excel'}}));
  put = async <T>(url: string, data: any) =>
    firstValueFrom(this.http.put<T>(url, data));
  delete = async <T>(url: string) =>
    firstValueFrom(this.http.delete<T>(url));
}
