import {Injectable} from '@angular/core';
import {Company, CompanySite, Document} from '../../model';
import {_Service} from './_service';
import {buildQuery} from '../../util';

export type PreviewDocument = Document & { preview: string };

@Injectable({providedIn: 'root'})
export class DocumentService extends _Service<Document> {
  constructor() {
    super('api/document');
  }

  upload = (file: File, type: string, title: string, onProgress: (progress: number) => void) =>
    this.http.postUpload<Document>('api/document', onProgress, file, type, title);

  download = async (id: string, inviteCode?: string) =>
    this.http.getBlob(`api/document/${id}/download${inviteCode ? `?inviteCode=${inviteCode}` : ''}`);
  preview = async (id: string, inviteCode?: string) =>
    this.http.get<PreviewDocument>(`api/document/${id}/preview` + buildQuery({inviteCode}));
  previewList = async (ids: string[]) =>
    this.http.post<{ [key: string]: PreviewDocument }>(`api/document/preview`, ids);
  addCompanyDocument = async ({id}: Company, document: Document) =>
    this.http.post(`api/company/${id}/document`, {id: document.id});
  addCompanySiteDocument = async ({id}: CompanySite, document: Document) =>
    this.http.post(`api/site/${id}/document`, {id: document.id});
}
