import {inject, Injectable} from '@angular/core';
import {AsyncHttpClient} from "./async-http-client.service";
import {ImportResult} from '../../model';

@Injectable({providedIn: 'root'})
export class ApiService {
  private readonly http = inject(AsyncHttpClient);
  adminUpdateLocations = async () =>
    this.http.post<any>('api/admin/import-locations', {});
  adminWipe = async () =>
    this.http.post<ImportResult[]>('api/admin/wipe', {});
  adminExec = async (cypher: string) =>
    this.http.post<ImportResult[]>('api/admin/exec', {cypher});
  adminExecDownload = async (cypher: string) =>
    this.http.postBlobXls('api/admin/exec-download', {cypher});
  adminDump = async () =>
    this.http.getText('api/admin/dump/database-full');
  adminTesting = async (url: string) =>
    this.http.post<any>('api/admin/gpt-test', {url});
  loadShape = async (id: string): Promise<any> =>
    this.http.get<any>(`api/geojson/user-shape/${id}`);
  loadShapeShort = async (id: string): Promise<any> =>
    this.http.get<any>(`api/geojson/${encodeURI(id)}`);
  exploreLandLink = async (id: string): Promise<any> =>
    this.http.get<{ url: string }>(`api/geojson/explore-land/${encodeURI(id)}`);
  teams = async () =>
    this.http.get('api/team/all');
}
