import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, NewsArticle} from '../../model';
import {buildQuery} from '../../util';

type CountResult = { risk?: AppId, locations: AppId[], count: number, date: Date, news: AppId[] };

@Injectable({providedIn: 'root'})
export class NewsArticleService extends _Service<NewsArticle> {
  constructor() {
    super('api/news-article');
  }

  import = async (id: string) =>
    this.http.post(`api/news-article/import-google/${id}`, null);
  count = async (data: { risk?: AppId, locations: AppId[] }[]) =>
    (await this.http.post<CountResult[]>('api/news-article/count', data))
      .map(entry => ({...entry, locations: entry.locations.sort()}));
  previewImage = async (url: string) =>
    this.http.getText('api/news-article/preview-image' + buildQuery({url}));
}
