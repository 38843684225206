import {Component, Inject, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BaseListComponent, BaseListPaginatorComponent} from '../../common';
import {MatChipsModule} from '@angular/material/chips';
import {GlobalInfo, HasIdName, NewsArticle} from '../../model';
import {NewsArticleService} from '../../common/service/news-article.service';
import {OverflowGradientDirective} from '../../common/overflow-gradient.directive';

type Filter = { search?: string | null, risks?: string[], locations?: string[], noRating?: boolean };

@Component({
  selector: 'app-news-dialog',
  standalone: true,
  templateUrl: 'news-dialog.component.html',
  styleUrls: ['news-dialog.component.scss'],
  providers: [{provide: BaseListComponent, useExisting: NewsDialogComponent}],
  imports: [
    CommonModule, MatDialogModule, MatChipsModule, MatToolbarModule, MatButtonModule, MatIconModule,
    BaseListPaginatorComponent, OverflowGradientDirective
  ]
})
export class NewsDialogComponent implements OnInit {
  globalInfo: GlobalInfo = {countries: [], siteType: []};
  filter: Filter = {} as any;
  private service = inject(NewsArticleService);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { risks: HasIdName[], locations: HasIdName[] }) {
    this.filter = {
      risks: data.risks.map(({id}) => (id)),
      locations: data.locations.map(({id}) => (id)),
      noRating: false,
    };
  }

  async ngOnInit() {
    await this.search();
  }

  private search = async () => {
    this.page = {
      ...(await this.service.listLimit(this.filter, 'page')),
      search: () => this.search()
    };
  };

  page = {
    totalElements: 0, pageSize: 0, pageNumber: 0, content: [] as NewsArticle[],
    search: () => this.search()
  };
}
