<mat-toolbar mat-dialog-title>
  How to model a Supply Chain
</mat-toolbar>

<mat-dialog-content style="padding: 1em 1em">
  <img alt="tiers" ngSrc="/assets/chain-logic.png" width="551" height="268"><br/>
  To model a supply chain, it is essential to provide evidence of the connection between suppliers.
  This evidence may include any type of delivery documentation, such as packing lists. Additionally,
  for each company in the supply chain, you should include documents relevant to ESG criteria
  (such as certificates, operating licenses, harvesting permits, etc.).

  <br/><br/>
  A tier represents a level within your supply chain. <em>Tier 1</em> represents your direct suppliers, <em>tier 2</em>
  are the suppliers providing materials to your <em>tier 1</em> suppliers, and so on.
</mat-dialog-content>
