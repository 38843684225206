import {ReactiveFormsModule} from '@angular/forms';
import {Component} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DialogFormActionsComponent} from '../../common';

@Component({
  selector: 'app-chain-overview-dialog',
  standalone: true,
  templateUrl: 'chain-overview-dialog.component.html',
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, DialogFormActionsComponent, MatToolbarModule, MatIconModule,
    MatButtonModule, MatInputModule, MatSelectModule, MatDatepickerModule, NgOptimizedImage,
  ]
})
export class ChainOverviewDialogComponent {
}
