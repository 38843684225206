import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CommonModule, formatDate} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatLineModule} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AutotrimDirective, DialogFormActionsComponent} from '../../common';
import {Company, CompanySite, Document, DocumentType} from '../../model';
import {DocumentService} from '../../common/service/document.service';

interface UploadStatus {
  subscription: Subscription;
  name: string;
  progress: number;
  error?: string;
}

@Component({
  selector: 'app-document-upload-dialog',
  standalone: true,
  templateUrl: 'document-upload-dialog.component.html',
  styleUrls: ['document-upload-dialog.component.scss'],
  imports: [
    CommonModule, ReactiveFormsModule, MatDialogModule, MatRadioModule, MatIconModule, MatDatepickerModule,
    MatInputModule, MatLineModule, MatButtonModule, MatProgressBarModule, MatToolbarModule, DialogFormActionsComponent,
    AutotrimDirective
  ]
})
export class DocumentUploadDialogComponent implements OnDestroy, AfterViewInit {
  title: string;
  uploadStatus?: UploadStatus;
  uploadResult?: Document;
  fileInput!: HTMLInputElement;
  company?: Company;
  site?: CompanySite;
  type?: string;
  types: DocumentType[];
  form = new FormBuilder().nonNullable.group({
    type: ['', Validators.required],
    date: [null as Date | null, Validators.required],
    title: ['', Validators.required]
  });
  step: 1 | 2 = 1;

  constructor(private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
              private service: DocumentService, @Inject(MAT_DIALOG_DATA) data: any) {
    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        try {
          await this.removeUpload();
        } catch (ignore) {
        }
      }
    });
    this.title = data.title;
    this.types = data.types;
    this.form.patchValue({type: data.types.length ? data.types[0].id : undefined});
    this.company = data.company;
    this.site = data.site;
  }

  ngAfterViewInit(): void {
    this.resetFileInput();
  }

  ngOnDestroy() {
    this.uploadStatus?.subscription?.unsubscribe();
    this.fileInput?.remove();
  }

  async removeUpload() {
    this.uploadStatus?.subscription?.unsubscribe();
    this.uploadStatus = undefined;

    if (this.uploadResult) {
      try {
        await this.service.delete(this.uploadResult);
      } catch (ignore) {
      }
      this.uploadResult = undefined;
    }
    this.resetFileInput();
  }

  resetFileInput() {
    this.fileInput?.remove();
    const input = this.fileInput = document.createElement('input');
    input.type = 'file';
    input.onchange = () => {
      const fileObj = input.files && input.files.length ? input.files[0] : undefined;
      if (fileObj) {
        const hasDot = fileObj.name.lastIndexOf('.');
        const title = hasDot > 0 ? fileObj.name.substring(0, hasDot) : fileObj.name;
        this.form.patchValue({title});
        const upload: UploadStatus = this.uploadStatus = {
          name: fileObj.name,
          progress: 0,
          subscription: this.service.upload(
            fileObj, this.form.value.type!!, title, (progress) => upload.progress = progress
          ).subscribe({
            next: (result) => {
              this.uploadResult = result;
              this.uploadStatus = undefined;
              this.form.patchValue({date: new Date(result.date)});
            },
            error: () => upload.error = 'upload failed, please retry'
          })
        };
      }
    };
    input.style.display = 'none';
    document.body.append(input);
    this.cdr.detectChanges();
  }

  async save() {
    if (this.uploadResult) {
      const {date, type, title} = this.form.value as { date: Date, type: string, title: string };
      await this.service.update(this.uploadResult.id, {
        ...this.uploadResult,
        title,
        type: {id: type, name: undefined as any},
        date: formatDate(date!, 'YYYY-MM-dd', 'en-US')
      });
      this.dialogRef.close({...this.uploadResult, title});
    }
  }

  startUpload() {
    this.step = 2;
    this.fileInput.click();
  }
}
