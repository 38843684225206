import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {AppId, HasId, Location} from '../../model';
import {buildQuery} from '../../util';

@Injectable({providedIn: 'root'})
export class LocationService extends _Service<Location> {
  constructor() {
    super('api/location');
  }

  listAll = async (q: string) =>
    q == '' ? [] : this.http.get<Location[]>('api/location/all' + buildQuery({q}));
  listById = async (ids: string[]) =>
    ids.length == 0 ? [] : this.http.get<Location[]>('api/location/id' + buildQuery({ids}));
  uploadShx = (file: File, onProgress: (progress: number) => void) =>
    this.http.postUpload<UploadResult>('api/location/shx-upload', onProgress, file);
  deleteShx = async (id: AppId) =>
    await this.http.delete(`api/location/shx-upload/${id}`);
}

export interface UploadResult extends HasId {
  center: { lng: number, lat: number };
}
