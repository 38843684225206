import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatLineModule} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatToolbarModule} from '@angular/material/toolbar';
import {HeaderActionsComponent} from '../../common';
import {LocationService, UploadResult} from '../../common/service/location.service';
import * as mapboxgl from 'mapbox-gl';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../common/service/api.service';


@Component({
  selector: 'app-shape-upload-dialog',
  standalone: true,
  templateUrl: 'shape-upload-dialog.component.html',
  styleUrls: ['shape-upload-dialog.component.scss'],
  imports: [
    CommonModule, MatDialogModule, MatRadioModule, MatIconModule, MatDatepickerModule, MatInputModule, MatLineModule,
    MatButtonModule, MatProgressBarModule, MatToolbarModule, HeaderActionsComponent
  ]
})
export class ShapeUploadDialogComponent implements OnDestroy, AfterViewInit {
  uploadStatus?: Subscription;
  fileName?: string;
  progress: number = 0;
  error?: string;
  uploadResult?: UploadResult & { fresh: boolean };
  fileInput!: HTMLInputElement;
  @ViewChild('mapContainer') mapContainer!: ElementRef;
  map?: mapboxgl.Map;

  constructor(private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<ShapeUploadDialogComponent>,
              private api: ApiService,
              private service: LocationService, @Inject(MAT_DIALOG_DATA) data: any) {
    this.dialogRef.afterClosed().subscribe(async (result) => (!result) && await this.removeUpload());
    this.uploadResult = data.shape;
  }

  showMap() {
    if (!this.uploadResult) return;

    const lat = this.uploadResult.center.lat;
    const lng = this.uploadResult.center.lng;
    const d = 0.5;
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: this.mapContainer.nativeElement,
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      bounds: [[lng - d, lat - d], [lng + d, lat + d]],
      center: [lng, lat]
    })
      .addControl(new mapboxgl.NavigationControl())
      .on('load', async () => {

        const data = await this.api.loadShape(this.uploadResult!!.id);
        this.map!.addSource('source-user-shx', {type: 'geojson', data});
        this.map!.addLayer({
          'id': 'shx-user-layer',
          'type': 'fill',
          'source': 'source-user-shx',
          'paint': {
            'fill-color': 'red',
            'fill-opacity': 0.4
          }
        });
        this.map!.resize();
      });
  }

  ngAfterViewInit(): void {
    this.resetFileInput();
    if (this.uploadResult) {
      this.showMap();
    } else {
      this.startUpload();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.uploadStatus?.unsubscribe();
    this.fileInput?.remove();
  }

  async removeUpload() {
    this.uploadStatus?.unsubscribe();
    this.uploadStatus = undefined;

    if (this.uploadResult) {
      if (this.uploadResult.fresh) {
        try {
          await this.service.deleteShx(this.uploadResult.id);
        } catch (ignore) {
        }
      }
      this.uploadResult = undefined as any;
      this.map?.remove();
    }
    this.resetFileInput();
  }

  resetFileInput() {
    this.fileInput?.remove();
    const input = this.fileInput = document.createElement('input');
    input.type = 'file';
    input.onchange = () => {
      const fileObj = input.files && input.files.length ? input.files[0] : undefined;
      if (fileObj) {
        this.error = undefined;
        this.fileName = fileObj.name;
        this.uploadStatus = this.service.uploadShx(fileObj, (progress) => this.progress = progress)
          .subscribe({
            next: (result) => {
              this.uploadResult = {...result, fresh: true};
              this.uploadStatus = undefined;
              this.showMap();
            },
            error: () => this.error = 'upload failed, please retry'
          });
      }
    };
    input.style.display = 'none';
    document.body.append(input);
    this.cdr.detectChanges();
  }

  startUpload() {
    this.fileInput.click();
  }
}
